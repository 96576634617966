import Vue from 'vue'
import select from 'select-dom'
import on from 'dom-event'
import '../nav-main/nav-main.vue'
import '../menu-item/menu-item.vue'

const SCROLLING_HEADER_CLASS = 'is-scrolling'

export default el => new Vue({
  name: 'Header',
  el,
  mounted () {
    this.setMainStyle()
    this.hideHeaderOnScrollDown()
    on(window, 'resize', this.setMainStyle)
    on(window, 'orientationchange', this.setMainStyle)
    on(window, 'scroll', this.hideHeaderOnScrollDown)
  },
  methods: {
    getPromoBarHeight() {
      const promobarEl = document.querySelector('.js-promobar')
      return promobarEl ? promobarEl.offsetHeight - 1 : 0
    },
    getHeaderHeight() {
      const headerEl = document.querySelector('.js-header')
      return headerEl ? headerEl.offsetHeight - 1 : 0
    },
    setMainStyle () {
      setTimeout(() => {
        const mainEl = document.querySelector('.js-main')
        const hasHeaderOffset =
          document.body.classList.contains('force-active-nav')
        const mainFullHeight = document.body.classList.contains(
          'force-main-full-height'
        )
        const offsetHeight = hasHeaderOffset
          ? this.getHeaderHeight()
          : this.getPromoBarHeight()

        if (mainEl && offsetHeight) {
          mainEl.style.marginTop = `${offsetHeight}px`
          document.documentElement.style.setProperty(
            '--header-offset',
            `${offsetHeight}px`
          )

          if (mainFullHeight) {
            const windowHeight = window.innerHeight
            mainEl.style.minHeight = `${windowHeight - offsetHeight}px`
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          } else {
            mainEl.style.minHeight = null
          }
        } else if (mainEl) {
          document.documentElement.style.setProperty('--header-offset', '0px')
          mainEl.style.marginTop = null
          mainEl.style.minHeight = null
        }
      }, 0)
    },
    hideHeaderOnScrollDown() {
      const body = document.body
      const scrollTop = window.scrollY
      if (scrollTop > 0) {
        body.classList.add(SCROLLING_HEADER_CLASS)
      } else {
        body.classList.remove(SCROLLING_HEADER_CLASS)
      }
    },
  }
})